html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: #000065;
  font-size: 1.5em;
  padding: 10px;
  margin: 10px;
}

h2 {
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
}

h3 {
  font-family: 'Montserrat', sans-serif;  
  line-height: 2em;
}

p {
  font-family: 'Open sans' sans-serif;
}

@media (max-width: 600px) {
  a {
    font-size: 1em;
    padding: 5px;
  }
}